(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/assets/javascripts/helpers/utils.js') >= 0) return;  svs.modules.push('/components/components/form/assets/javascripts/helpers/utils.js');

(function(svs) {
  'use strict';

  svs.components = svs.components || {};
  svs.components.form = svs.components.form || {};
  svs.components.form.utils = svs.components.form.utils || {};

  svs.components.form.utils.extend = function(obj, src) {
    for (var key in src) {
      if (Object.prototype.hasOwnProperty.call(src, key)) {
        obj[key] = src[key];
      }
    }
    return obj;
  };
})(svs);


 })(window);