(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/views/form.js') >= 0) return;  svs.modules.push('/components/components/form/views/form.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.form=_cmps.form||{};
_cmps.form.templates=_cmps.form.templates||{};
svs.components.form.templates.form = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " animate animate-show animate-delay-2ms";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"formData") : depth0)) != null ? lookupProperty(stack1,"multipleAnswers") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":27,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"grid-row\">\n                    <div class=\"col-xs-12 col-sm-12\">\n                        <div class=\"f-content align-center\">\n                            <p id=\"confirm\"><strong>Du har uppnått max antal tillåtna svar!</strong></p>\n                            <p>Ditt bidrag registrerades: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"formData") : depth0)) != null ? lookupProperty(stack1,"submitted") : stack1), depth0))
    + "</p>\n                        </div>\n                    </div>\n                </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"grid-row\">\n                    <div class=\"col-xs-12 col-sm-12\">\n                        <div class=\"f-content align-center\">\n                            <p id=\"confirm\"><strong>Formulär skickat!</strong></p>\n                            <p>Ditt bidrag registrerades: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"formData") : depth0)) != null ? lookupProperty(stack1,"submitted") : stack1), depth0))
    + "</p>\n                        </div>\n                    </div>\n                </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"grid-row\">\n            <div class=\"col-xs-12 col-sm-12\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"formData") : depth0)) != null ? lookupProperty(stack1,"formItems") : stack1),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":14},"end":{"line":33,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-form-form_credentials"),(depth0 != null ? lookupProperty(depth0,"formData") : depth0),{"name":"components-components-form-form_credentials","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-form-form_send"),(depth0 != null ? lookupProperty(depth0,"formData") : depth0),{"name":"components-components-form-form_send","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial((lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"components-components-form-form_item_",(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":32,"column":71}}}),depth0,{"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"standard-form\">\n  <div class=\"bg-white margin-1 pop-up-box\">\n    <div class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"noAnimation") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":89}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-form-form_close"),depth0,{"name":"components-components-form-form_close","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-form-form_media"),(depth0 != null ? lookupProperty(depth0,"formData") : depth0),{"name":"components-components-form-form_media","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <div class=\"form-content padding-xs-2 padding-md-3\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-form-form_title_ingress_body"),(depth0 != null ? lookupProperty(depth0,"formData") : depth0),{"name":"components-components-form-form_title_ingress_body","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"formData") : depth0)) != null ? lookupProperty(stack1,"submitted") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n</div>";
},"usePartial":true,"useData":true});
Handlebars.partials['components-components-form-form'] = svs.components.form.templates.form;
})(svs, Handlebars);


 })(window);