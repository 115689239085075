(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/views/form-close.js') >= 0) return;  svs.modules.push('/components/components/form/views/form-close.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.form=_cmps.form||{};
_cmps.form.templates=_cmps.form.templates||{};
svs.components.form.templates.form_close = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"\" class=\"js-navigate\">\n  <button class=\"icon-button padding-xs-1 padding-sm-2 right\">\n    "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"icon":"close","class":"fc-grey-600","size":"200"},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":61}}}))
    + "\n  </button>\n</a>\n<div class=\"clearfix\"></div>";
},"useData":true});
Handlebars.partials['components-components-form-form_close'] = svs.components.form.templates.form_close;
})(svs, Handlebars);


 })(window);