(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/assets/javascripts/helpers/handlebar-helpers.js') >= 0) return;  svs.modules.push('/components/components/form/assets/javascripts/helpers/handlebar-helpers.js');
(function() {
  'use strict';

  if (typeof exports === 'object') {
    registerHelpers(require('hbs'));
  } else {
    registerHelpers(Handlebars);
  }

  function registerHelpers(hbs) {
    hbs.registerHelper('join', function() {
      var params = Array.prototype.slice.call(
        arguments,
        0,
        arguments.length - 1
      );
      var i = 0;
      var iLen = params.length;
      var res = '';

      for (; i < iLen; i++) {
        res += params[i];
      }
      return res;
    });
  }
})();


 })(window);