(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/views/form-send.js') >= 0) return;  svs.modules.push('/components/components/form/views/form-send.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.form=_cmps.form||{};
_cmps.form.templates=_cmps.form.templates||{};
svs.components.form.templates.form_send = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-toggle margin-bottom-2\">\n          <input type=\"checkbox\" value=\"\" class=\"form-toggle-input form-toggle-checkbox form-toggle-default js-input\" data-required=\"Markera att du tagit del av villkoren\" id=\"checkbox-terms\" name=\"checkbox[]\">\n          <label class=\"form-label form-label-align-left\" for=\"checkbox-terms\">\n            <div class=\"form-label-btn\"></div>\n            <div class=\"form-label-text\">\n              Jag godkänner\n            </div>\n          </label>\n          <label class=\"form-label\"><a href=\"#\" class=\"js-form-terms\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"formTermRelation") : depth0)) != null ? lookupProperty(stack1,"title") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":13,"column":70},"end":{"line":13,"column":177}}})) != null ? stack1 : "")
    + "</a></label>\n          <div class=\"clear error-message\"></div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"formTermRelation") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + ".";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"formTermRelation") : depth0)) != null ? lookupProperty(stack1,"article") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + ".";
},"6":function(container,depth0,helpers,partials,data) {
    return "Skicka";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row f-content\">\n  <div class=\"col-xs-12\">\n    <div class=\"control-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"formTermRelation") : depth0)) != null ? lookupProperty(stack1,"formTermId") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + "      "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(alias1,"button",{"name":"ui","hash":{"class":"js-form-send w100 btn-300 btn-default btn-inverted"},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":17,"column":94}}})) != null ? stack1 : "")
    + "\n    </div>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-components-form-form_send'] = svs.components.form.templates.form_send;
})(svs, Handlebars);


 })(window);