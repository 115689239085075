(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/views/form-item-checkbox.js') >= 0) return;  svs.modules.push('/components/components/form/views/form-item-checkbox.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.form=_cmps.form||{};
_cmps.form.templates=_cmps.form.templates||{};
svs.components.form.templates.form_item_checkbox = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row\">\n    <div class=\"col-xs-12\">\n        <div class=\"control-group\">\n            <div class=\"form-toggle margin-bottom-1 left\">\n                <input type=\"checkbox\" class=\"form-toggle-input form-toggle-checkbox form-toggle-default js-input\" id=\"checkbox-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":5,"column":128},"end":{"line":5,"column":134}}}) : helper)))
    + "\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":5,"column":148},"end":{"line":5,"column":158}}}) : helper)))
    + "\">\n                <label class=\"form-label form-label-align-left\" for=\"checkbox-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":6,"column":78},"end":{"line":6,"column":84}}}) : helper)))
    + "\">\n                    <div class=\"form-label-btn\"></div>\n                    <div class=\"form-label-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":8,"column":49},"end":{"line":8,"column":58}}}) : helper)))
    + "</div>\n                </label>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});
Handlebars.partials['components-components-form-form_item_checkbox'] = svs.components.form.templates.form_item_checkbox;
})(svs, Handlebars);


 })(window);