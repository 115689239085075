(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/views/form-credentials.js') >= 0) return;  svs.modules.push('/components/components/form/views/form-credentials.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.form=_cmps.form||{};
_cmps.form.templates=_cmps.form.templates||{};
svs.components.form.templates.form_credentials = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row f-content\">\n  <div class=\"col-xs-12\">\n    <div class=\"f-bold\">Dina uppgifter</div>\n    <div class=\"description\">\n      <span>Namn: </span>\n      <span class=\"data-hj-suppress\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"uFirstName") || (depth0 != null ? lookupProperty(depth0,"uFirstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uFirstName","hash":{},"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":51}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"uLastName") || (depth0 != null ? lookupProperty(depth0,"uLastName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uLastName","hash":{},"data":data,"loc":{"start":{"line":7,"column":52},"end":{"line":7,"column":65}}}) : helper)))
    + "</span>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"verifiedStreet") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"description\">\n      <span>Mobilnummer: </span>\n        <span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mobile") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":32,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"retailer") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":0},"end":{"line":42,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"description\">\n        <span>Adress: </span>\n        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"verifiedStreet") || (depth0 != null ? lookupProperty(depth0,"verifiedStreet") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"verifiedStreet","hash":{},"data":data,"loc":{"start":{"line":12,"column":14},"end":{"line":12,"column":32}}}) : helper)))
    + ", "
    + alias4(((helper = (helper = lookupProperty(helpers,"verifiedCity") || (depth0 != null ? lookupProperty(depth0,"verifiedCity") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"verifiedCity","hash":{},"data":data,"loc":{"start":{"line":12,"column":34},"end":{"line":12,"column":50}}}) : helper)))
    + "</span>\n      </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"mobile") || (depth0 != null ? lookupProperty(depth0,"mobile") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"mobile","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":26}}}) : helper)))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"cellphone") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":12}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cellphone") || (depth0 != null ? lookupProperty(depth0,"cellphone") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cellphone","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":29}}}) : helper)))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                <i>Mobilnummer saknas</i>\n            ";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"description margin-bottom-1\">\n        <span>E-post: </span>\n        <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":30,"column":14},"end":{"line":30,"column":23}}}) : helper)))
    + "</span>\n      </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"grid-row padding-top-1 margin-bottom-1 f-content\">\n        <div class=\"col-xs-12\">\n            <div>Kontrollera att dina uppgifter stämmer.</div>\n            <div> Ändringar utförs under <a href=\"\" class=\"js-navigate-profile\">Min profil.</a></div>\n        </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"playerProperties") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":43,"column":9}}})) != null ? stack1 : "");
},"useData":true});
Handlebars.partials['components-components-form-form_credentials'] = svs.components.form.templates.form_credentials;
})(svs, Handlebars);


 })(window);