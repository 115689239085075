(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/views/form-item-select.js') >= 0) return;  svs.modules.push('/components/components/form/views/form-item-select.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.form=_cmps.form||{};
_cmps.form.templates=_cmps.form.templates||{};
svs.components.form.templates.form_item_select = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " data-required=\"Välj ett av alternativen\" ";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"defaultText") || (depth0 != null ? lookupProperty(depth0,"defaultText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"defaultText","hash":{},"data":data,"loc":{"start":{"line":7,"column":88},"end":{"line":7,"column":103}}}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    return "Välj";
},"7":function(container,depth0,helpers,partials,data) {
    return "                <option>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row f-content\">\n  <div class=\"col-xs-12\">\n    <div class=\"control-group\">\n      <label for=\"form-select\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":31},"end":{"line":4,"column":40}}}) : helper)))
    + "</label>\n      <div class=\"select-label\">\n          <select class=\"select select-default js-input\" id=\"form-select\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":74},"end":{"line":6,"column":139}}})) != null ? stack1 : "")
    + " data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":6,"column":152},"end":{"line":6,"column":162}}}) : helper)))
    + "\">\n            <option value=\"\" disabled=\"disabled\" selected=\"selected\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"defaultText") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":7,"column":69},"end":{"line":7,"column":122}}})) != null ? stack1 : "")
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":10,"column":23}}})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      <span class=\"error-message\"></span>\n      <div class=\"info-message\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"infoMessage") || (depth0 != null ? lookupProperty(depth0,"infoMessage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"infoMessage","hash":{},"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":47}}}) : helper)))
    + "</div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-components-form-form_item_select'] = svs.components.form.templates.form_item_select;
})(svs, Handlebars);


 })(window);