(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/views/form-item-radio.js') >= 0) return;  svs.modules.push('/components/components/form/views/form-item-radio.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.form=_cmps.form||{};
_cmps.form.templates=_cmps.form.templates||{};
svs.components.form.templates.form_item_radio = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-toggle\">\n              <input type=\"radio\" class=\"form-toggle-input form-toggle-radio form-toggle-default js-input\" value=\""
    + alias2(alias1(depth0, depth0))
    + "\" id=\"radio-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":9,"column":144},"end":{"line":9,"column":154}}}) : helper)))
    + "\" name=\"toggle-radio-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "\" data-index=\""
    + alias2(alias1((container.data(data, 1) && lookupProperty(container.data(data, 1),"index")), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"required") : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":213},"end":{"line":9,"column":281}}})) != null ? stack1 : "")
    + ">\n              <label class=\"form-label form-label-align-left\" for=\"radio-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":10,"column":83},"end":{"line":10,"column":93}}}) : helper)))
    + "\">\n                <div class=\"form-label-btn\"></div>\n                <div class=\"form-label-text\">"
    + alias2(alias1(depth0, depth0))
    + "</div>\n              </label>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " data-required=\"Välj ett av alternativen\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset>\n  <div class=\"grid-row f-content\">\n    <div class=\"col-xs-12\">\n      <div class=\"control-group\">\n        <label>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":15},"end":{"line":5,"column":24}}}) : helper)))
    + "</label>\n        <div class=\"radio-label\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "          <span class=\"error-message\"></span>\n        </div>\n      </div>\n    </div>\n  </div>\n</fieldset>\n";
},"useData":true,"useDepths":true});
Handlebars.partials['components-components-form-form_item_radio'] = svs.components.form.templates.form_item_radio;
})(svs, Handlebars);


 })(window);