(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/views/form-item-text.js') >= 0) return;  svs.modules.push('/components/components/form/views/form-item-text.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.form=_cmps.form||{};
_cmps.form.templates=_cmps.form.templates||{};
svs.components.form.templates.form_item_text = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " data-required=\"Fältet får inte vara tomt\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row f-content\">\n  <div class=\"col-xs-12\">\n    <div class=\"control-group\">\n      <label for=\"competition-form-input\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":51}}}) : helper)))
    + "</label>\n      <input class=\"js-input std-input\" type=\"text\" id=\"competition-form-input\" value=\"\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":5,"column":101},"end":{"line":5,"column":111}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":113},"end":{"line":5,"column":179}}})) != null ? stack1 : "")
    + "/>\n      <span class=\"error-message\"></span>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-components-form-form_item_text'] = svs.components.form.templates.form_item_text;
})(svs, Handlebars);


 })(window);