(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/components/form/assets/javascripts/prescript.js');

var svs = svs || {};

(function(svs) {
  svs.components = svs.components || {};
  svs.components.form = svs.components.form || {};
  svs.components.form.formData = svs.components.formData || {};
  svs.components.form.log = svs.core.log.getLogger('components:form');
})(svs);


 })(window);