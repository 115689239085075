(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/views/form-item-textarea.js') >= 0) return;  svs.modules.push('/components/components/form/views/form-item-textarea.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.form=_cmps.form||{};
_cmps.form.templates=_cmps.form.templates||{};
svs.components.form.templates.form_item_textarea = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " data-required=\"Fältet får inte vara tomt\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row f-content\">\n  <div class=\"col-xs-12\">\n    <div class=\"control-group\">\n      <label for=\"form-message\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":41}}}) : helper)))
    + "</label>\n                <textarea id=\"form-message\"\n                          name=\"form-message\"\n                          class=\"js-input std-input\"\n                          maxlength=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maxLength") || (depth0 != null ? lookupProperty(depth0,"maxLength") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxLength","hash":{},"data":data,"loc":{"start":{"line":8,"column":37},"end":{"line":8,"column":50}}}) : helper)))
    + "\"\n                          rows=\"5\"\n                          data-validate-html-free=\"Texten får inte innehålla html-tecken.\"\n                          data-validate-url-free=\"Texten får inte innehålla länkar.\"\n                          placeholder=\"\"\n                          data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":48}}}) : helper)))
    + "\"\n                          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":26},"end":{"line":14,"column":92}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":14,"column":93},"end":{"line":14,"column":101}}}) : helper)))
    + "</textarea>\n      <span class=\"error-message\"></span>\n      <span class=\"info-message\">Max "
    + alias4(((helper = (helper = lookupProperty(helpers,"maxLength") || (depth0 != null ? lookupProperty(depth0,"maxLength") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxLength","hash":{},"data":data,"loc":{"start":{"line":16,"column":37},"end":{"line":16,"column":50}}}) : helper)))
    + " tecken</span>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-components-form-form_item_textarea'] = svs.components.form.templates.form_item_textarea;
})(svs, Handlebars);


 })(window);