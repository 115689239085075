(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/assets/javascripts/form.js') >= 0) return;  svs.modules.push('/components/components/form/assets/javascripts/form.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  const branding = () => {
    let dialogBranding = svs.ui.dialog.branding.NEUTRAL;
    if (svs.core.detect.tenant('tb')) {
      dialogBranding = svs.ui.dialog.branding.TUR;
    } else if (svs.core.detect.tenant('lb')) {
      dialogBranding = svs.ui.dialog.branding.SPORT;
    }
    return dialogBranding;
  };

  var log = svs.core.log.getLogger('components:form');
  var showLeaveMessageBox = function(callback) {
    const dialog = new svs.ui.dialog.Confirm({
      area: svs.ui.dialog.area.POPUP,
      branding: branding(),
      convertToPopup: false,
      icon: 'exclamation-sign',
      type: svs.ui.dialog.type.default,
      title: 'Lämna sidan?',
      message: [
        {
          type: svs.ui.dialog.message.TEXT,
          text: 'Formuläret har inte skickats. Är du säker på att du vill lämna sidan?',
          align: svs.ui.dialog.message.align.CENTER

        }
      ],
      actions: [
        {
          title: 'Avbryt',
          callback: function() {
            dialog.close();
          }
        },
        {
          title: 'Lämna sidan?',
          callback: function() {
            dialog.close();
            callback();
          }
        }
      ]
    });

    svs.ui.dialog.api.add(dialog);
  };
  var displayModal = function(props) {
    var btnEnabled = {
      visible: true,
      text: 'Klar',
      callback: function() {
        svs.components.Modal.hide();
        return false;
      }
    };
    var btnDisabled = {
      visible: false,
      triggerScrollEnd: true
    };

    var options = {
      modalHistory: false,
      title: props.title || '',
      leftButton: btnEnabled,
      rightButton: btnDisabled
    };
    svs.components.Modal.show(props.template, {}, options);
  };

  svs.components.form.Form = function($el, closePath) {
    var isDirty = false;
    var formData = null;
    var $container = $el || $('.main-content');
    var onClose = function() {
      if (closePath) {
        window.location.href = closePath;
      } else {
        window.location.reload(true);
      }
    };
    var sendHandler = function() {
      var isValid = svs.components.formValidation.validateRequiredFields(
        null,
        false,
        true,
        function(element) {
          return element.attr('type') === 'checkbox';
        }
      );

      if (svs.core.userSession.isAnonymous()) {
        return svs.components.customer_login.login();
      } else if (isValid) {
        document.querySelector('.js-form-send').disabled = true;
        track('Avsluta formulär');
        svs.components.form.formData.save(formData, function(err) {
          isDirty = false;
          if (!err) {
            populateContainer(svs.components.form.templates.form({
              formData: formData,
              noAnimation: true
            }));

            const dialog = new svs.ui.dialog.Confirm({
              area: svs.ui.dialog.area.POPUP,
              branding: branding(),
              convertToPopup: false,
              type: svs.ui.dialog.type.default,
              title: formData.properties.cnt_confirmHeading,
              message: [
                {
                  type: svs.ui.dialog.message.TEXT,
                  text: formData.properties.cnt_confirmBody,
                  align: svs.ui.dialog.message.align.CENTER

                }
              ],
              actions: [
                {
                  title: formData.c2a ? formData.c2a[0].linkTitle : 'Stäng',
                  callback: function() {
                    dialog.close();
                    window.location.href = formData.c2a ? formData.c2a[0].linkUrl : svs.core.urlMapping.get('startPage');
                  }
                }
              ]
            });

            svs.ui.dialog.api.add(dialog);
          }
        });
      }
    };
    var termsHandler = function() {
      displayModal({
        template: svs.components.form.templates.form_terms(formData.formTermRelation.article)
      });
    };
    var populateContainer = function(html) {
      $container
        .css('max-width', '1280px')
        .css('margin', 'auto')
        .html(html);
      window.scrollTo(0, 0);
      var visibleContentArea =
          $('body').height() - ($('#top-content').height() + 24);
      $container.find('.pop-up-box').css('min-height', visibleContentArea);
    };
    var track = function(action) {
      svs.components.analytics.trackEvent({
        category: 'Formulär',
        opt_label: formData.name + '_' + formData.id,
        action: action
      });
    };
    var registerClickListener = function(className, callback) {
      $container.on('click', className, function(e) {
        callback(e);
        return false;
      });
    };
    var registerRadioChangeListener = function() {
      $container.on(
        'change',
        'input[type=radio], input[type=checkbox]',
        function() {
          svs.components.formValidation.hideErrorMessage(this.id);
        }
      );
    };
    var registerAnswerListener = function() {
      $container.on('change keyup', '.js-input', function() {
        var index = $(this).data('index');
        var formItem = formData.formItems[index];

        if (formItem) {
          if (formItem.type === 'checkbox') {
            formItem.answer = !formItem.answer;
          } else {
            formItem.answer = $(this).val();
          }

          isDirty = true;
        }
      });
    };
    var registerListeners = function() {
      registerClickListener('.js-form-send', sendHandler);
      registerClickListener('.js-form-terms', termsHandler);
      registerClickListener('.js-navigate', function() {
        if (isDirty) {
          showLeaveMessageBox(onClose);
        } else {
          onClose();
        }
      });
      registerClickListener('.js-navigate-profile', function() {
        showLeaveMessageBox(function() {
          closePath = svs.core.urlMapping.get('myProfileHome');
          onClose();
        });
      });
      registerRadioChangeListener();
      registerAnswerListener();
    };

    this.fetchData = function(id, callback) {
      svs.components.form.formData.fetch(id, function(err, data) {
        if (!err) {
          formData = data;
        }
        callback(err);
      });
    };

    this.render = function() {
      if (formData) {
        track('Påbörja formulär');
        populateContainer(svs.components.form.templates.form({ formData: formData }));
        registerListeners();
        svs.components.formValidation.init();
      } else {
        log.error('Render called without form data');
      }
    };

    this.destroy = function() {
      $container.off();
    };
  };
})(svs);


 })(window);